import * as R from "ramda";
import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {
    fetching: {},
    fields: {
      byId: {},
    },
    submissions: {
      byId: {},
    },
    eventTemplates: {
      byId: {},
    },
    recordReferences: {
      byId: {},
    },
    fieldReferences: {
      byId: {},
    },
    support: {
      articles: {},
    },
    attend: {
      authorizing: true,
      event: {},
      color: "#425bc2",
      isNetworkingEnabled: false,
      defaultStageId: null,
      defaultRoomId: null,
      isPaymentEnabled: false,
      eventStripeToken: null,
      auctionTerms: null,
      hasAuction: false,
      hasVirtualVenue: false,
      isExternalBiddingEnabled: false,
      isBillingAddressRequired: false,
      live: [],
      sessions: [],
      countOfCartAlerts: 0,
      cartItems: [],
      paymentMethods: [],
      preferences: {
        can_access_rooms: [],
        can_access_stages: [],
        has_enabled_phone_number: false,
        has_enabled_update_notifications: false,
        session_checkins: {},
        session_favorites: {},
        session_notifications: {},
      },
      auth: {
        is_bid_only_user: false,
        user_id: null,
        contact_id: null,
        is_admin: null,
        has_access: null,
        has_payment_method: null,
        attendee_id: null,
        ticket_id: null,
        ticket_name: null,
        first_name: null,
        last_name: null,
        mobile_phone: null,
        sms_optin: null,
        photo_url: null,
        enabled_stage_chat: false,
      },
      userProfile: {},
    },
    helpIconOpened: false,
    isAttendCartRequestLoading: false,
  },
  reducers: {
    error: R.identity,
    fetching: R.identity,
    approve: R.identity,
    deny: R.identity,
    undoReview: R.identity,
    addReferences: ({ fieldReferences, recordReferences }, { payload }) => ({
      fieldReferences: {
        byId: R.mergeAll([
          fieldReferences.byId,
          R.indexBy(
            R.prop("id"),
            R.reduce(
              (fields, module) =>
                R.concat(
                  fields,
                  R.pathOr(
                    R.propOr([], "fields", module),
                    ["fields", "fields"],
                    module,
                  ),
                ),
              [],
              R.values(payload),
            ),
          ),
        ]),
      },
      recordReferences: {
        byId: R.mergeAll([
          recordReferences.byId,
          R.indexBy(
            R.prop("id"),
            R.reduce(
              (records, module) => {
                const val = R.propOr([], "records", module);
                if (Array.isArray(val)) {
                  return R.concat(records, val);
                }
                const recordsWithId = R.map(
                  ([id, val]) => ({ id, ...val }),
                  Object.entries(val),
                );
                return R.concat(records, recordsWithId);
              },
              [],
              R.values(payload),
            ),
          ),
        ]),
      },
    }),
    addSubmission: ({ fields, submissions }, { payload: submission }) => ({
      submissions: {
        ...submissions,
        byId: {
          ...submissions.byId,
          [submission.id]: submission,
        },
      },
      fields: {
        ...fields,
        byId: R.mergeAll([
          fields.byId,
          R.indexBy(R.prop("id"), R.pathOr([], ["form", "fields"], submission)),
        ]),
      },
    }),
    addEventTemplate: (state, { payload: { data, eventUUID } }) =>
      data && eventUUID
        ? {
            eventTemplates: {
              ...state.eventTemplates,
              byId: R.mergeAll([
                state.eventTemplates.byId,
                { [eventUUID]: data },
              ]),
            },
          }
        : state,
    supportResponse: (_, { payload: { articles } }) => ({
      support: {
        articles,
      },
    }),
    //
    attendRequest: R.identity,
    attendProfileRequest: R.identity,
    attendAuthRequest: R.identity,
    attendPreferencesRequest: R.identity,
    // attendAddItemToCart: R.identity,
    attendCartRequest: R.identity,
    attendResponse: (
      _,
      { payload: { attend, auth, userProfile, preferences } },
    ) => ({
      attend: {
        authorizing: false,
        event: attend.event,
        registrationUrl: attend.registration_url,
        color: attend.color,
        template: attend.template,
        details: attend.details,
        isNetworkingEnabled: attend.is_networking_enabled,
        defaultStageId: attend.stage_id,
        defaultRoomId: attend.room_id,
        isPaymentEnabled: attend.is_payment_enabled,
        eventStripeToken: attend.event_stripe_token,
        live: attend.live,
        sessions: attend.sessions,
        hasAuction: attend.enable_bidding,
        hasVirtualVenue: attend.enable_virtual_venue,
        auctionTerms: attend.auction_terms,
        isExternalBiddingEnabled: attend.enable_external_bidding,
        isBulkPayEnabled: attend.is_bulk_pay_enabled,
        isBillingAddressRequired: attend.is_billing_address_required,
        auth,
        userProfile,
        preferences,
        enableAuctionOnlyMode: R.propOr(
          false,
          "enable_auction_only_mode",
          attend,
        ),
        enableFundraiserStageOverlay: R.propOr(
          false,
          "enable_fundraiser_stage_overlay",
          attend,
        ),
        enableFundraiserLeaderboard: R.propOr(
          false,
          "enable_fundraiser_leaderboard",
          attend,
        ),
        enableFundraiserActivity: R.propOr(
          false,
          "enable_fundraiser_activity",
          attend,
        ),
        enableStageChat: R.propOr(false, "enable_stage_chat", attend),
      },
    }),
    updateAttendProfile: ({ attend }, { payload: userProfile }) => ({
      attend: R.merge(attend, {
        userProfile,
      }),
    }),
    updateAttendAuth: ({ attend }, { payload: auth }) => ({
      attend: R.merge(attend, {
        auth,
      }),
    }),
    updateAttendCart: (state, { payload: cart }) => ({
      attend: {
        ...state.attend,
        countOfCartAlerts: cart.count ? parseInt(cart.count, 10) : 0,
        cartItems: cart.items_won,
      },
    }),
    updateAttendPaymentMethods: (state, { payload: paymentMethods }) => ({
      attend: {
        ...state.attend,
        paymentMethods: paymentMethods ? paymentMethods : [],
      },
    }),
    updateAttendPreferences: ({ attend }, { payload: preferences }) => ({
      attend: R.merge(attend, {
        preferences,
      }),
    }),
    attendAddLiveScope: (state, { payload: { scope, scopeId } }) => {
      return {
        attend: {
          ...state.attend,
          live: R.uniqBy(R.prop("scopeId"))([
            ...state.attend.live,
            {
              scope,
              scopeId,
            },
          ]),
        },
      };
    },
    attendRemoveLiveScope: (state, { payload: { scopeId } }) => {
      return {
        attend: {
          ...state.attend,
          live: R.filter((l) => l.scopeId !== scopeId)(state.attend.live),
        },
      };
    },
  },
  namespace: "Data",
});

const { actions, getters } = model;

export { actions, getters };

export default model;
