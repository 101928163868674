import { put, call, all, select, take, spawn } from "redux-saga/effects";

import { actions } from "../model";
import * as R from "ramda";

import { apiCall } from "./utils";

import { getAttendEventUUID } from "../selectors";

import {
  getCredentials,
  userId as getUserId,
} from "redux/modules/user/selectors";

const watchOnFetchCart = function* () {
  for (;;) {
    yield take(actions.attendCartRequest.type);
    const eventUUID = yield select(getAttendEventUUID);
    try {
      yield put(actions.setIsAttendCartRequestLoading(true));
      const [{ payload: alertsPayload }, { payload: paymentMethodsPayload }] =
        yield all([
          call(apiCall, {
            method: "get",
            url: `/attend/event/${eventUUID}/bidding/alerts`,
          }),
          call(apiCall, {
            method: "get",
            url: `/attend/event/${eventUUID}/payment/methods`,
          }),
        ]);
      yield put(actions.updateAttendCart(alertsPayload));
      yield put(actions.updateAttendPaymentMethods(paymentMethodsPayload));
    } catch (error) {
      throw error;
    } finally {
      yield put(actions.setIsAttendCartRequestLoading(false));
    }
  }
};

const watchOnRefetchProfile = function* () {
  for (;;) {
    yield take(actions.attendProfileRequest.type);
    const userId = yield select(getUserId);
    const eventUUID = yield select(getAttendEventUUID);
    try {
      const { payload: attendProfile } = yield call(apiCall, {
        method: "get",
        url: `/attend/event/${eventUUID}/people/${userId}`,
      });
      yield put(actions.updateAttendProfile(attendProfile));
    } catch (error) {
      throw error;
    }
  }
};

const watchOnRefetchAuth = function* () {
  for (;;) {
    yield take(actions.attendAuthRequest.type);
    const eventUUID = yield select(getAttendEventUUID);
    try {
      const { payload: auth } = yield call(apiCall, {
        method: "get",
        url: `/attend/event/${eventUUID}/auth`,
      });
      yield put(actions.updateAttendAuth(auth));
    } catch (error) {
      throw error;
    }
  }
};

const watchOnRefetchPreferences = function* () {
  for (;;) {
    yield take(actions.attendPreferencesRequest.type);
    const eventUUID = yield select(getAttendEventUUID);
    try {
      const { payload: preferences } = yield call(apiCall, {
        method: "get",
        url: `/attend/event/${eventUUID}/preferences`,
      });
      yield put(actions.updateAttendPreferences(preferences));
    } catch (error) {
      throw error;
    }
  }
};

export const getAttend = function* ({
  eventUUID,
  transactionId,
  throwNoAccessException = true,
}) {
  try {
    yield put(actions.fetching(transactionId));

    // get attend information
    const result = yield call(apiCall, {
      method: "get",
      url: `/attend/event/${eventUUID}`,
      public: true,
    });

    // get user auth for virtual event
    const credentials = yield select(getCredentials);
    let authResult = {};

    if (credentials && credentials.userId) {
      authResult = yield call(apiCall, {
        method: "get",
        url: `/attend/event/${eventUUID}/auth`,
      });
    }

    if (
      !authResult ||
      !authResult.payload ||
      // only allow people with a proper registration
      (!authResult.payload.has_access &&
        // or is a bid-only user
        !authResult.payload.is_bid_only_user)
    ) {
      if (!throwNoAccessException) {
        yield put(
          actions.attendResponse({
            attend: result.payload,
            auth: R.propOr({}, "payload", authResult), // we need to save also the attendee data to display the name
            userProfile: {},
            preferences: {},
          }),
        );
        return result;
      } else {
        throw new Error("User does not have valid ticket");
      }
    }

    let userProfileResult = {};
    if (credentials && credentials.userId) {
      userProfileResult = yield call(apiCall, {
        method: "get",
        url: `/attend/event/${eventUUID}/people/${credentials.userId}`,
      });
    }

    const { payload: preferences } = yield call(apiCall, {
      method: "get",
      url: `/attend/event/${eventUUID}/preferences`,
    });

    yield put(
      actions.attendResponse({
        attend: result.payload,
        auth: authResult.payload,
        userProfile: userProfileResult.payload,
        preferences,
      }),
    );

    yield spawn(watchOnRefetchProfile);
    yield spawn(watchOnRefetchAuth);
    yield spawn(watchOnRefetchPreferences);
    yield spawn(watchOnFetchCart);

    return result;
  } catch (error) {
    yield put(actions.error(error, { meta: { transactionId } }, true));
    throw error;
  } finally {
    yield put(actions.fetching(transactionId));
  }
};
