import * as R from "ramda";

import { delay, call, fork, take, cancel } from "redux-saga/effects";

export const makeFuture = () => {
  let done, error;
  const prom = new Promise((res, rej) => {
    done = res;
    error = rej;
  });

  return {
    done,
    error,
    onRealized: R.always(prom),
  };
};

export const loadElm = ({ elmType = "script", ...params }) => {
  const elm = document.createElement(elmType);

  if (R.has("id", params)) {
    const oldElm = document.getElementById(params.id);

    if (oldElm) {
      oldElm.remove();
    }
  }

  for (const key in params) {
    elm[key] = params[key];
  }

  return new Promise((res) => {
    elm.addEventListener("load", res);

    const heads = document.getElementsByTagName("head");
    const head = heads[0];

    head.appendChild(elm);
  }).then(R.always(elm));
};

export const debounce = (pattern, delegate, time = 250) => {
  const delayAndCall = function* (...args) {
    yield delay(time);
    yield call(delegate, ...args);
  };

  return function* () {
    let task;
    while (true) {
      const action = yield take(pattern);
      if (task) {
        yield cancel(task);
      }
      task = yield fork(delayAndCall, action);
    }
  };
};

function checkImgSize(file) {
  return new Promise(function (resolve, reject) {
    const maxWidth = 2240;
    const maxHeight = 2240;
    const blob = file.originalFile;

    // Get an object URL for the local file
    const url = URL.createObjectURL(blob);

    // Create an image and load the object URL
    const img = new Image();
    img.src = url;

    img.onload = function () {
      URL.revokeObjectURL(url);

      if (this.naturalHeight > maxHeight && this.naturalWidth > maxWidth) {
        reject(
          "File is too wide and too tall. Max width is 2240px and max height is 2240px",
        );
      }

      if (this.naturalWidth > maxWidth) {
        reject("File is too wide. Max width is 2240px");
      }

      if (this.naturalHeight > maxHeight) {
        reject("File is too tall. Max height is 2240px");
      }

      // If we made it here then the file was approved
      resolve();
    };
  });
}

export const openFilePicker = ({
  multiple = false,
  displayMode = "overlay",
  fromSources = ["local_file_system", "dropbox"],
  maxSize = 2147483648, // 2gb
  storeTo = {},
  transformations = {},
  accept,
  checkImageSize = false,
}) =>
  new Promise((res, rej) => {
    // eslint-disable-next-line no-underscore-dangle
    const filepicker = window.filestack.init(window.__FILEPICKER_API_KEY__);

    if (filepicker) {
      filepicker
        .picker(
          R.filter(R.compose(R.not, R.isNil), {
            maxFiles: multiple ? 50 : 1,
            displayMode,
            fromSources,
            maxSize,
            storeTo: {
              location: "s3",
              path: "event-files/",
              ...storeTo,
            },
            onUploadDone: ({ filesUploaded }) => res(filesUploaded),
            onFileUploadFailed: rej,
            /**
             * used for validating image size
             */
            exposeOriginalFile: !!checkImageSize,
            onFileSelected: checkImageSize ? checkImgSize : undefined,
            transformations: {
              crop: true,
              circle: false,
              rotate: false,
              ...transformations,
            },
            accept,
          }),
        )
        .open();
    } else if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error("Filestack not initialized");
    }
  });

export const renderDropZone = ({
  multiple = false,
  displayMode = "dropPane",
  container = "dropzone-container",
  fromSources = ["local_file_system", "dropbox"],
  maxSize = 2147483648, // 2gb
  storeTo = {},
  transformations = {},
  accept,
}) =>
  new Promise((res, rej) => {
    // eslint-disable-next-line no-underscore-dangle
    const filepicker = window.filestack.init(window.__FILEPICKER_API_KEY__);

    if (filepicker) {
      filepicker
        .picker(
          R.filter(R.compose(R.not, R.isNil), {
            maxFiles: multiple ? 50 : 1,
            displayMode,
            container,
            fromSources,
            maxSize,
            storeTo: {
              location: "s3",
              path: "event-files/",
              ...storeTo,
            },
            onUploadDone: ({ filesUploaded }) => res(filesUploaded),
            dropPane: {
              overlay: false,
              customText:
                "Drag and drop a file here or click to choose one from your computer. We accept files up to 2GB",
            },
            onFileUploadFailed: rej,
            transformations: {
              crop: true,
              circle: false,
              rotate: false,
              ...transformations,
            },
            accept,
          }),
        )
        .open();
    } else if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error("Filestack not initialized");
    }
  });

export const openFileTransform = function* (fileUrl) {
  // eslint-disable-next-line no-underscore-dangle
  const filepicker = window.filestack.init(window.__FILEPICKER_API_KEY__);
  // eslint-disable-next-line no-underscore-dangle
  const tr = new window.FilestackTransform(window.__FILEPICKER_API_KEY__);
  tr.setConfig({
    lang: "en",
    editor: {
      transforms: {
        enabled: true,
        lockRotation: false,
        lockFlip: false,
        ratios: [
          {
            icon: "crop_free",
            default: true,
            name: "Custom",
            shape: "rect",
          },
          {
            icon: `<svg><circle cx="10" cy="10" r="8" stroke="white" stroke-width="2" fill="transparent"/></svg>`,
            name: "Circle",

            lockRatio: true, // Controls whether the ratio is user-editable
          },
          {
            icon: "crop_2_3",
            name: "2/3", // The default translation for this ratio
            shape: "rect", // rect, circle
            ratio: 2 / 3, // The image aspect ratio (a floating point number) or
            lockRatio: true, // Controls whether the ratio is user-editable
          },
          {
            icon: "crop_9_16",
            name: "9/16", // The default translation for this ratio
            shape: "rect", // rect, circle
            ratio: 9 / 16, // The image aspect ratio (a floating point number) or
            lockRatio: true, // Controls whether the ratio is user-editable
          },
        ],
      },
      text: {
        enabled: false,
      },
      filters: {
        enabled: false,
      },
      adjustments: {
        enabled: false,
      },
    },
    output: {
      format: "png",
      quality: 1,
      blob: true,
      name: null,
    },
  });

  const res = yield call(R.always(tr.open(fileUrl)));
  return yield call(R.always(filepicker.upload(res)));
};
