import * as R from "ramda";

const getParams = R.propOr({}, "params");
const createGetId = (key) => R.compose(R.propOr("", key), getParams);

export const getFieldId = createGetId("fieldId");
export const getSubmissionId = createGetId("submissionId");

export const getHasSkippedPaymentMethodPreviously = (userId, eventUUID) => {
  return (
    localStorage.getItem(`skipPaymentMethod-${userId}-${eventUUID}`) === "true"
  );
};

export const setSkipPaymentMethod = (userId, eventUUID) => {
  if (userId && eventUUID) {
    localStorage.setItem(`skipPaymentMethod-${userId}-${eventUUID}`, "true");
  }
};
